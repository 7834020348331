<template>
    <div class="c-Sections u-fixed u-fit-w  u-color--white u-select-none u-overflow-h" v-bind:class="{ 'is-page-ready': isPageReady, 'is-page-enter': isPageEnter }">
        <div class="u-relative u-fit-w u-viewport-fit-h" v-bind:style="{ height: minHeight +'px' }"
            v-on:mousedown="onDragStart"
            v-on:touchstart.passive="onDragStart"
            v-on:mousemove="onDrag"
            v-on:touchmove.passive="onDrag"
            v-on:mouseup="onDragEnd"
            v-on:touchend.passive="onDragEnd"
            v-on:touchleave.passive="onDragEnd"
            v-on:mouseleave="onDragEnd">

            <div v-bind:class="{ 'is-active-up': currentIndex === 0 && currentIndex < previousIndex, 'is-active-down': currentIndex === 0 && currentIndex > previousIndex, 'is-leave-up':  0 < currentIndex, 'is-leave-down': 0 > currentIndex }" class="c-Sections-item c-Sections-one u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w10of16 u-offset-l-w4of16 u-force-3d | u-w12of16@lg u-offset-l-w3of16@lg | u-w14of16@md u-offset-l-w2of16@md | u-fit-w@sm u-offset-l-w0of16@sm">
                    <div class="c-Sections-one-title t-h1">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-one-title-line-1 u-block u-offset-l-w2of10 | u-offset-l-w1of8@sm">The Future</span>

                        <div class="u-fit-w u-flex u-flex-flow-row-wrap u-marg-t-xl |  u-marg-t-0@sm">
                            <span class="c-Sections-line c-Sections-line--1 c-Sections-one-title-line-2 u-block">of innovation</span>

                            <div class="c-Sections-one-desc u-w4of10 u-pad-l-lg | u-w6of8@sm u-offset-l-w1of8@sm u-pad-l-0@sm">
                                <div class="c-Sections-line c-Sections-line--3">
                                    <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                        <svg class="c-Sections-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                            <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                            <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                            <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                        </svg>
                                        <span>section 01</span>
                                    </span>
                                </div>

                                <p class="c-Sections-line c-Sections-line--4 c-Sections-text t-text--sm u-text-left">Innovation remains a strong priority in the wake of COVID-19 but with clear concerns about the future of innovation progress.</p>
                            </div>
                        </div>

                        <router-link to="/sections/future-of-innovation" v-bind:class="{ 'is-touch u-flex': isTouch }" class="c-Sections-line c-Sections-line--3 c-Sections-btn c-Sections-one-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md | u-hide@sm">
                            <span class="t-text--xs u-color--white">View</span>
                        </router-link>
                    </div>
                </div>

                <router-link to="/sections/future-of-innovation" class="c-Sections-line c-Sections-line--3 c-Sections-btn c-Sections-one-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@sm">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link>

                <router-link to="/sections/future-of-innovation" draggable="false" class="t-link u-absolute u-pos-tl u-fit | u-hide@md">
                    <cursor-trigger v-bind:name="'view'" class="u-absolute u-pos-tl u-fit"></cursor-trigger>
                </router-link>

                <!-- <router-link to="/sections/future-of-innovation" class="c-Sections-line c-Sections-line--3 c-Sections-btn c-Sections-one-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link> -->
            </div>

            <div v-bind:class="{ 'is-active-up': currentIndex === 1 && currentIndex < previousIndex, 'is-active-down': currentIndex === 1 && currentIndex > previousIndex, 'is-leave-up':  1 < currentIndex, 'is-leave-down': 1 > currentIndex }" class="c-Sections-item c-Sections-two u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w8of16 u-offset-l-w4of16 | u-w10of16@lg u-offset-l-w3of16@lg | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-Sections-two-title t-h1">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-two-title-line-1 u-block | u-offset-l-w1of10@lg | u-marg-l-0@sm">A World of</span>
                        <span class="c-Sections-line c-Sections-line--1 c-Sections-two-title-line-2 u-block u-offset-l-w3of8 | u-offset-l-w2of8@sm">Innovation</span>
                        <span class="c-Sections-line c-Sections-line--2 c-Sections-two-title-line-3 u-hide | u-block@sm u-offset-l-w1of8@sm">Nations</span>
                    </h1>

                    <div class="u-relative u-flex u-fit-w">
                        <div class="u-w5of8 u-text-right u-pad-r-lg | u-hide@sm">
                            <span class="c-Sections-line c-Sections-line--2 c-Sections-two-title-line-3 t-h1 u-block | c-Sections-line-mobile--4">Nations</span>
                        </div>

                        <div class="c-Sections-two-desc u-w3of8 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-Sections-line c-Sections-line--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-Sections-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 02</span>
                                </span>
                            </div>

                            <p class="c-Sections-line c-Sections-line--4 c-Sections-text t-text--sm">Countries have more authority over their innovation strategies and growing confidence to innovate on their own.</p>
                        </div>
                    </div>
                </div>

                <router-link to="/sections/world-of-innovations-nations" draggable="false" class="t-link u-absolute u-pos-tl u-fit | u-hide@md">
                    <cursor-trigger v-bind:name="'view'" class="u-absolute u-pos-tl u-fit"></cursor-trigger>
                </router-link>

                <router-link to="/sections/world-of-innovations-nations" v-bind:class="{ 'is-touch u-flex': isTouch }" class="c-Sections-line c-Sections-line--3 c-Sections-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link>
            </div>

            <div v-bind:class="{ 'is-active-up': currentIndex === 2 && currentIndex < previousIndex, 'is-active-down': currentIndex === 2 && currentIndex > previousIndex, 'is-leave-up':  2 < currentIndex, 'is-leave-down': 2 > currentIndex }" class="c-Sections-item c-Sections-three u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w11of16 u-offset-l-w3of16 | u-w12of16@lg u-offset-l-w2of16@lg | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-Sections-three-title t-h1">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-three-title-line-1 u-block">A Redefinition</span>
                        <span class="c-Sections-line c-Sections-line--1 c-Sections-three-title-line-2 u-block u-offset-l-w4of11 | u-offset-l-w1of8@sm">of the Global</span>
                        <span class="c-Sections-line c-Sections-line--2 c-Sections-three-title-line-3 u-hide | u-block@sm">Innovation</span>
                        <span class="c-Sections-line c-Sections-line--3 c-Sections-three-title-line-4 u-hide | u-block@sm">Influence Map</span>
                    </h1>

                    <div class="c-Sections-three-foot u-relative u-flex u-fit-w">
                        <div class="u-block u-w8of11 u-text-right u-pad-r-lg | u-hide@sm">
                            <span class="c-Sections-line c-Sections-line--2 c-Sections-three-title-line-3 u-block t-h1">Innovation</span>
                            <span class="c-Sections-line c-Sections-line--3 c-Sections-three-title-line-4 u-block t-h1">Influence Map</span>
                        </div>

                        <div class="c-Sections-three-desc u-w3of11 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-Sections-line c-Sections-line--2 | c-Sections-line-mobile--4">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-Sections-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 03</span>
                                </span>
                            </div>

                            <p class="c-Sections-line c-Sections-line--3 c-Sections-text t-text--sm | c-Sections-line-mobile--5">Beyond the battle for Innovation leadership between the USA and China.</p>
                        </div>
                    </div>
                </div>

                <router-link  to="/sections/redefinition-of-global-innovation" draggable="false" class="t-link u-absolute u-pos-tl u-fit | u-hide@md">
                    <cursor-trigger v-bind:name="'view'" class="u-absolute u-pos-tl u-fit"></cursor-trigger>
                </router-link>

                <router-link  to="/sections/redefinition-of-global-innovation" v-bind:class="{ 'is-touch u-flex': isTouch }" class="c-Sections-line c-Sections-line--3 c-Sections-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link>
            </div>

            <div v-bind:class="{ 'is-active-up': currentIndex === 3 && currentIndex < previousIndex, 'is-active-down': currentIndex === 3 && currentIndex > previousIndex, 'is-leave-up':  3 < currentIndex, 'is-leave-down': 3 > currentIndex }" class="c-Sections-item c-Sections-four u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w10of16 u-offset-l-w3of16 | u-w12of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-Sections-four-title t-h1 u-w7of10 u-offset-l-w3of10 | u-fit-w@sm u-marg-l-0@sm">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-four-title-line-1 u-block">Protectionism</span>
                        <span class="c-Sections-line c-Sections-line--1 c-Sections-four-title-line-2 u-hide | u-block@sm">Continues</span>
                        <span class="c-Sections-line c-Sections-line--2 c-Sections-four-title-line-3 u-hide u-offset-l-w5of16 | u-block@sm">to Grow</span>
                    </h1>

                    <div class="c-Sections-four-foot u-relative u-flex u-align-items-fe u-fit-w">
                        <div class="u-marg-r-lg | u-hide@sm">
                            <span class="c-Sections-line c-Sections-line--2 c-Sections-four-title-line-2 t-h1 u-block">Continues</span>
                        </div>

                        <div class="c-Sections-four-desc u-w3of10 | u-w4of10@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-Sections-line c-Sections-line--2  | c-Sections-line-mobile--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-Sections-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 04</span>
                                </span>
                            </div>

                            <p class="c-Sections-line c-Sections-line--3 c-Sections-text t-text--sm | c-Sections-line-mobile--4">Despite a desire to collaborate and partner more, executives believe that protectionist policies can have a net-positive effect on innovation.</p>
                        </div>
                    </div>
                    <div class="u-w5of10 u-offset-l-w2of10 | u-hide@sm">
                        <span class="c-Sections-line c-Sections-line--4 c-Sections-four-title-line-3 t-h1 u-block">to Grow</span>
                    </div>
                </div>

                <router-link to="/sections/protectionism-continues-to-grow" draggable="false" class="t-link u-absolute u-pos-tl u-fit | u-hide@md">
                    <cursor-trigger v-bind:name="'view'" class="u-absolute u-pos-tl u-fit"></cursor-trigger>
                </router-link>

                <router-link to="/sections/protectionism-continues-to-grow" v-bind:class="{ 'is-touch u-flex': isTouch }" class="c-Sections-line c-Sections-line--3 c-Sections-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link>
            </div>

            <div v-bind:class="{ 'is-active-up': currentIndex === 4 && currentIndex < previousIndex, 'is-active-down': currentIndex === 4 && currentIndex > previousIndex, 'is-leave-up':  4 < currentIndex, 'is-leave-down': 4 > currentIndex }" class="c-Sections-item c-Sections-five u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w10of16 u-offset-l-w4of16 | u-w13of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-Sections-five-title t-h1">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-five-title-line-1 u-block">Jobs and</span>
                        <span class="c-Sections-line c-Sections-line--1 c-Sections-five-title-line-2 u-block u-offset-l-w4of10 | u-offset-l-w5of16@sm">Artificial</span>
                        <span class="c-Sections-line c-Sections-line--2 c-Sections-five-title-line-3 u-hide | u-block@sm u-offset-l-w2of16@sm">Intelligence</span>
                    </h1>

                    <div class="u-relative u-flex u-fit-w">
                        <div class="u-text-right u-w7of10 u-pad-r-lg | u-hide@sm">
                            <span class="c-Sections-line c-Sections-line--2 c-Sections-five-title-line-3 t-h1 u-block">Intelligence</span>
                        </div>

                        <div class="c-Sections-five-desc u-w3of10 | u-w6of8@sm u-offset-l-w1of8@sm">
                            <div class="c-Sections-line c-Sections-line--3">
                                <span class="t-text--xxs u-font-bold u-uppercase u-flex u-align-items-c">
                                    <svg class="c-Sections-icon u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                                        <path fill="#FFFFFF" d="M10.9 7.9c-.6-.8-1.4-1.5-2.3-1.9.9-.4 1.7-1.1 2.3-1.9.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8l-.2-.3-.1.3c-.1.3-.2.5-.4.8C9.3 4.5 7.7 5.4 6 5.4c-1.7 0-3.3-.9-4.2-2.3-.2-.3-.3-.5-.4-.8L1.3 2l-.2.3c-.2.3-.4.5-.6.8v.2c.1.3.3.6.5.8.7.8 1.5 1.5 2.4 1.9-.9.4-1.7 1.1-2.3 1.9-.2.3-.4.5-.6.8v.2c.1.3.3.5.5.8l.3.3.1-.3c.1-.3.2-.5.4-.8.9-1.4 2.5-2.3 4.2-2.3 1.7 0 3.3.9 4.2 2.3.2.3.3.5.4.8l.1.3.2-.3c.2-.3.4-.5.5-.8v-.2c-.1-.3-.3-.5-.5-.8z"/>
                                        <circle fill="#FFFFFF" cx="6" cy="1.2" r="1.2"/>
                                        <circle fill="#FFFFFF" cx="6" cy="10.8" r="1.2"/>
                                    </svg>
                                    <span>section 05</span>
                                </span>
                            </div>

                            <p class="c-Sections-line c-Sections-line--4 c-Sections-text t-text--sm">Workforce concerns mount. Artificial Intelligence adaption skyrockets.</p>
                        </div>
                    </div>
                </div>

                <router-link to="/sections/jobs-and-artificial-intelligence" draggable="false" class="t-link u-absolute u-pos-tl u-fit | u-hide@md">
                    <cursor-trigger v-bind:name="'view'" class="u-absolute u-pos-tl u-fit"></cursor-trigger>
                </router-link>

                <router-link to="/sections/jobs-and-artificial-intelligence" v-bind:class="{ 'is-touch u-flex': isTouch }" class="c-Sections-line c-Sections-line--3 c-Sections-btn t-btn t-link u-align-items-c u-justify-content-c u-bg--blue-light u-hide | u-flex@md">
                    <span class="t-text--xs u-color--white">View</span>
                </router-link>
            </div>

            <section v-bind:class="{ 'is-active-up': currentIndex === 5 && currentIndex < previousIndex, 'is-active-down': currentIndex === 5 && currentIndex > previousIndex, 'is-leave-up':  5 < currentIndex, 'is-leave-down': 5 > currentIndex }" class="c-Sections-item c-Sections-six u-absolute u-pos-tl u-fit u-overflow-h c-Container u-flex u-align-items-c | u-align-items-fe@sm">
                <div class="u-relative u-w9of16 u-offset-l-w4of16 | u-w10of16@lg u-offset-l-w3of16@lg | u-w12of16@md u-offset-l-w2of16@md | u-fit-w@sm u-marg-l-0@sm">
                    <h1 class="c-Sections-six-title t-h1">
                        <span class="c-Sections-line c-Sections-line--0 c-Sections-six-title-line-1 u-offset-l-w2of9 u-block | u-offset-l-w3of16@sm">Download</span>
                        <span class="c-Sections-line c-Sections-line--1 c-Sections-six-title-line-2 u-hide | u-block@sm">Reports</span>
                    </h1>

                    <div class="u-relative u-flex u-fit-w">
                        <div class="u-w4of9 u-text-right | u-hide@sm">
                            <span class="c-Sections-line c-Sections-line--1 c-Sections-six-title-line-2 t-h1 u-block">Reports</span>
                        </div>

                        <div class="c-Sections-six-desc u-w4of9 u-offset-l-w1of9 | u-w7of8@sm u-offset-l-w1of8@sm">
                            <ul class="c-Sections-six-links c-Sections-line c-Sections-line--2 t-list">
                                <li>
                                    <a href="https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Report.pdf" target="_blank" rel="noopener noreferrer" class="t-link u-relative u-uppercase u-font-bold t-text--sm">
                                        <cursor-trigger v-bind:name="'download'" class="u-flex u-align-items-c">
                                            <span>download 2020 report</span>
                                            <svg class="u-marg-l-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="18" height="18">
                                                <path fill="#fff" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                                <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#fff"/>
                                            </svg>
                                        </cursor-trigger>
                                    </a>
                                </li>
                            </ul>

                            <ul class="c-Sections-six-btns c-Sections-line c-Sections-line--3 t-list">
                                <li><button type="button" v-on:click="openDownloadPanel(0)" class="c-Sections-six-btns-item t-btn t-text--sm u-text-left">See country specific reports</button></li>
                                <li><button type="button" v-on:click="openDownloadPanel(1)" class="c-Sections-six-btns-item t-btn t-text--sm u-text-left">2020 Global Innovation Barometer videos</button></li>
                                <!-- <li><a href="https://www.youtube.com/playlist?list=PLxRhTjvLlyoKB07l35uR8P2GFk037LFHX" target="_blank" rel="noopener noreferrer" class="c-Sections-six-btns-item t-btn t-text--sm u-text-left">2020 Global Innovation Barometer videos</a></li> -->
                                <li><button type="button" v-on:click="openDownloadPanel(2)" class="c-Sections-six-btns-item t-btn t-text--sm u-text-left">See reports from previous years</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <cursor-trigger v-bind:name="'scroll'" class="c-Sections-nav | u-hide@sm">
                <ul class="t-list">
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(0)" v-bind:class="{ 'is-active': currentIndex === 0 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>1/6</span></button>
                    </li>
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(1)" v-bind:class="{ 'is-active': currentIndex === 1 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>2/6</span></button>
                    </li>
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(2)" v-bind:class="{ 'is-active': currentIndex === 2 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>3/6</span></button>
                    </li>
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(3)" v-bind:class="{ 'is-active': currentIndex === 3 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>4/6</span></button>
                    </li>
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(4)" v-bind:class="{ 'is-active': currentIndex === 4 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>5/6</span></button>
                    </li>
                    <li class="c-Sections-nav-item">
                        <button v-on:click="goToIndex(5)" v-bind:class="{ 'is-active': currentIndex === 5 }" class="c-Sections-nav-item-btn t-btn t-text--xxs u-font-bold"><span>6/6</span></button>
                    </li>
                </ul>
            </cursor-trigger>

            <div v-bind:class="{ 'is-active': currentIndex !== 5 }" class="c-Sections-scroll c-Container u-absolute u-pos-bl u-fit-w is-active-down">
                <div class="c-Sections-line c-Sections-line--2  u-flex u-justify-content-sb u-align-items-c u-w6of8 u-offset-l-w1of8">
                    <cursor-trigger v-bind:name="'scroll-dark'" class="c-Sections-scroll-content u-inline-block">
                        <button v-on:click="goToIndex(currentIndex+1)" type="button" class="c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                    <p class="t-text--xxs u-font-bold u-hide | u-block@sm">{{ currentIndex+1 }}/6</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { Quart } from 'gsap';

    import normalizeWheel from '@/utilities/normalizeWheel';

    import CursorTrigger from '@/components/CursorTrigger';

    export default {
        name: 'Sections',

        components: {
            CursorTrigger,
        },

        props: {
            initIndex: { type: Number, default: 0 },
        },

        data() {
            return {
                isPageReady: false,
                isPageEnter: false,

                currentIndex: 0,
                previousIndex: -1,
            };
        },

        computed: {
            ...mapState('window', [
                'isTouch',
                'width',
                'minHeight',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'downloadPanelOpen',
            ]),
        },

        created() {
            this.nbItems = 6;

            this._onWheel = this.onWheel.bind(this);
            this._onKeyUp = this.onKeyUp.bind(this);
        },

        mounted() {
            this.currentIndex = this.initIndex;

            window.addEventListener('wheel', this._onWheel, false);
            window.addEventListener('keyup', this._onKeyUp, false);

            this.$eventHub.$on('page:ready', this.onPageReady);
            this.$eventHub.$on('page:change', this.onIndexChange);

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');
            });
        },

        beforeDestroy() {
            window.removeEventListener('wheel', this._onWheel);
            window.removeEventListener('keyup', this._onKeyUp);

            this.$eventHub.$off('page:ready', this.onPageReady);
        },

        methods: {
            ...mapActions('main', [
                'toggleDownloadPanelOpen',
                'toggleDownloadPanelActive',
                'setCurrentNav',
            ]),

            onWheel(event) {
                if(!this.isPageReady || this.navigationOpen || this.downloadPanelOpen) return;

                const normalizeValues = normalizeWheel(event);

                if ((normalizeValues.pixelY > 10 || normalizeValues.pixelY < -10) && !this.hasPeak) {
                    this.hasPeak = true;

                    let index = (normalizeValues.pixelY > 10) ? this.currentIndex + 1 : this.currentIndex - 1;
                    // index %= this.nbItems;
                    // index = (index < 0) ? index + this.nbItems : index;

                    this.onIndexChange(index);

                    setTimeout(() => {
                        this.hasPeak = false;
                    }, 2000);
                }
            },

            onKeyUp(event) {
                if(!this.isPageReady || this.navigationOpen|| this.downloadPanelOpen) return;

                let index = 0;

                if (event.keyCode === 40 || event.keyCode === 39) {
                    index = this.currentIndex + 1;
                } else if (event.keyCode === 38 || event.keyCode === 37) {
                    index = this.currentIndex - 1;
                } else {
                    return;
                }

                // index %= this.nbItems;
                // index = (index < 0) ? index + this.nbItems : index;

                this.onIndexChange(index);
            },

            nextSlide() {
                // const index = (this.currentIndex + 1 >= this.nbItems) ? 0 : this.currentIndex + 1;
                const index = this.currentIndex + 1;
                this.onIndexChange(index);
            },

            prevSlide() {
                // const index = (this.currentIndex - 1 < 0) ? this.nbItems : this.currentIndex - 1;
                const index = this.currentIndex - 1;
                this.onIndexChange(index);
            },

            goToIndex(index) {
                this.$eventHub.$emit('sections:goto', index);
                this.onIndexChange(index);
            },

            setIndex(index) {
                this.previousIndex = this.currentIndex;
                this.currentIndex = index;
            },

            onIndexChange(index) {
                if (index < -1 || index >= this.nbItems) return;

                if (index === -1) {
                    setTimeout(() => this.$router.push('/'), 1200);
                }

                this.previousIndex = this.currentIndex;
                this.currentIndex = index;

                // this.$eventHub.$emit('sections:change', this.currentIndex);

                this.setCurrentNav(index+1);

                if (index === 5) {
                    this.$eventHub.$emit('webgl:section', 'reports', 2.4, Quart.easeInOut);
                } else {
                    this.$eventHub.$emit('webgl:section', 'section'+(index+1), 2.4, Quart.easeInOut);
                }
            },

            onDragStart(event) {
                if (!this.isPageReady || this.navigationOpen || this.downloadPanelOpen) return;

                this.isDrag = true;

                this.touchEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;
                this.dragStart = this.touchEvent.clientY;
                this.delta = 0;
            },

            onDrag(event) {
                if (!this.isDrag) return;

                this.touchEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;
                this.delta = this.touchEvent.clientY - this.dragStart;
            },

            onDragEnd() {
                if (!this.isDrag) return;

                this.isDrag = false;

                const multiply = (this.isTouch) ? 5 : 8;
                if (Math.abs(this.delta) > this.minHeight / multiply) {
                    if (this.delta < 0) {
                        this.nextSlide();
                    } else {
                        this.prevSlide();
                    }
                }
            },

            onPageReady() {
                this.isPageEnter = true;

                this.setCurrentNav(this.initIndex+1);

                this.$eventHub.$emit('sections:change', this.currentIndex);
                this.$eventHub.$on('sections:change', this.goToIndex);

                if (this.initIndex === 5) {
                    this.$eventHub.$emit('webgl:section', 'reports', 2, Quart.easeOut);
                } else {
                    this.$eventHub.$emit('webgl:section', 'section'+(this.initIndex+1), 2, Quart.easeOut);
                }

                setTimeout(() => {
                    this.isPageReady = true;
                }, 800);
            },

            openDownloadPanel(target) {
                this.toggleDownloadPanelActive(target);

                setTimeout(() => {
                    this.$eventHub.$emit('webgl:pause');
                }, 800);

                this.$nextTick(() => {
                    this.toggleDownloadPanelOpen(true);
                });
            }
        },
    }
</script>

<style lang="stylus">
    .c-Sections {

        &-item {
            visibility hidden
            transition visibility 1.2s linear;

            &.is-active-down, &.is-active-up {
                visibility visible
            }
        }

        &-icon {
            margin-top -4px
            margin-right 12px
        }

        &-text {
            margin-top 20px
        }

        &-one {
            &-title {
                &-line {
                    &-2 {
                        @media $breakpoints.sm {
                            white-space: nowrap
                        }
                    }

                    &-3 {
                        margin-top 110px

                        @media $breakpoints.lg {
                            margin-top calc(110 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            margin-top calc(50 * 100vh / 1125)
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 150px
                    margin-bottom 95px
                }
            }

            &-icon {
                margin-top -4px
                margin-right 12px
            }

            &-text {
                margin-top 20px
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }

                &-btn {
                    opacity 0
                    transform translateZ(0)
                    transition opacity 0.8s $ease-out-quart
                    will-change opacity

                    .is-page-ready & {
                        opacity 1
                    }
                }
            }

            &-btn {
                // @media $breakpoints.xl {
                //     &.is-touch {
                //         // top 50%
                //         // margin-top 100px
                //         top 65vh
                //     }

                //     &.is-touch {
                //         // top 50%
                //         // margin-top 100px
                //         top 50vh
                //     }
                // }

                @media $breakpoints.sm {
                    bottom 300px
                }
            }
        }

        &-two {
            &-title {
                &-line {
                    &-2 {
                        margin-top 145px

                        @media $breakpoints.lg {
                            margin-top calc(145 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            // margin-top 75px
                            // margin-top 10vh
                            margin-top calc(75 * 100vh / 1125)
                        }
                    }

                    &-3 {
                        margin-top 5px

                        @media $breakpoints.sm {
                            margin-top 0
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 75px // 95px
                    // margin-top 12.67vh
                    // margin-top calc(95 * 100vh / 1125)
                    margin-bottom 95px
                    // margin-bottom 12.67vh
                    // margin-bottom calc(95 * 100vh / 900)
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            .c-Sections-btn {
                bottom 200px

                @media $breakpoints.sm {
                    bottom 225px
                }

                @media $breakpoints.smh {
                    bottom 190px
                }
            }
        }

        &-three {
            &-title {
                &-line-2 {
                    white-space nowrap
                }

                &-line-3 {
                    @media $breakpoints.sm {
                        // margin-top 65px
                        // margin-top 8.67vh
                        margin-top calc(65 * 100vh / 1125)
                    }
                }
            }

            &-foot {
                margin-top 110px

                @media $breakpoints.lg {
                    margin-top calc(110 * 100vh / 1125)
                }

                @media $breakpoints.sm {
                    margin-top 0
                }
            }

            &-desc {
                margin-top 25px

                @media $breakpoints.sm {
                    margin-top 75px // 75px
                    // margin-top 10vh
                    // margin-top calc(75 * 100vh / 1125)
                    margin-bottom 95px
                    // margin-bottom 12.67vh
                    // margin-bottom calc(95 * 100vh / 900)
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            .c-Sections-btn {
                bottom 200px

                @media $breakpoints.sm {
                    bottom 205px
                }

                @media $breakpoints.smh {
                    bottom 170px
                }
            }
        }

        &-four {
            &-title {
                &-line-2 {
                    top: 0.15em;
                    position: relative;

                    @media $breakpoints.sm {
                        top 0
                        // margin-top 60px
                        // margin-top 8vh
                        margin-top calc(60 * 100vh / 1125)
                    }
                }
                &-line-3 {
                    margin-top 15px

                    @media $breakpoints.sm {
                        margin-top 0
                    }
                }
            }

            &-foot {
                margin-top 80px

                @media $breakpoints.lg {
                    margin-top calc(80 * 100vh / 1125)
                }

                @media $breakpoints.sm {
                    margin-top 0
                }
            }

            &-desc {
                margin-top 25px

                @media $breakpoints.sm {
                    margin-top 75px
                    // margin-top 10vh
                    // margin-top calc(75 * 100vh / 900)
                    margin-bottom 95px
                    // margin-bottom 12.67vh
                    // margin-bottom calc(95 * 100vh / 900)
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            .c-Sections-btn {
                bottom 200px

                @media $breakpoints.sm {
                    bottom 250px
                }

                @media $breakpoints.smh {
                    bottom 200px
                }
            }
        }

        &-five {
            &-title {
                &-line {
                    &-2 {
                        margin-top 140px

                        @media $breakpoints.lg {
                            margin-top calc(140 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            // margin-top 80px
                            // margin-top 10.67vh
                            margin-top calc(80 * 100vh / 1125)
                        }
                    }

                    &-3 {
                        margin-top 5px

                        @media $breakpoints.sm {
                            margin-top 0
                        }
                    }
                }
            }

            &-desc {
                margin-top 30px

                @media $breakpoints.sm {
                    margin-top 75px // 115px
                    // margin-top 15.33vh
                    // margin-top calc(115 * 100vh / 900)
                    margin-bottom 95px
                    // margin-bottom 12.67vh
                    // margin-bottom calc(95 * 100vh / 900)
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            .c-Sections-btn {
                bottom 200px

                @media $breakpoints.sm {
                    bottom 195px
                }

                @media $breakpoints.smh {
                    bottom 160px
                }
            }
        }

        &-six {
            &-title {
                &-line {
                    &-2 {
                        margin-top 100px

                        @media $breakpoints.lg {
                            margin-top calc(100 * 100vh / 1125)
                        }

                        @media $breakpoints.sm {
                            margin-top 0
                        }
                    }
                }
            }

            &-desc {
                margin-top 120px

                @media $breakpoints.lg {
                    margin-top calc(120 * 100vh / 1125)
                }

                @media $breakpoints.sm {
                    margin-top 75px // 105px
                    // margin-top 14vh
                    // margin-top calc(105 * 100vh / 900)
                    margin-bottom 95px
                    // margin-bottom 12.67vh
                    // margin-bottom calc(95 * 100vh / 900)
                }

                @media $breakpoints.smh {
                    margin-top 40px
                }
            }

            &-links {
                li {
                    margin-top 10px

                    &:first-child {
                        margin-top 0
                    }

                    a {
                        letter-spacing 0.15em

                        transform translateZ(0)
                        transition color 0.6s $ease-out-quart
                        will-change color

                        svg path {
                            transition fill 0.6s $ease-out-quart
                            will-change fill
                        }

                        &:hover {
                            color $colors['blue-light'];

                            svg path {
                                fill $colors['blue-light'];
                            }
                        }
                    }
                }
            }

            &-btns {
                margin-top 60px

                @media $breakpoints.sm {
                    margin-top 65px
                }

                @media $breakpoints.smh {
                    margin-top 50px
                }

                &:hover {
                    .c-Sections-six-btns-item {
                        opacity 0.3
                    }
                }

                li {
                    margin-top 5px

                    @media $breakpoints.sm {
                        margin-top 15px
                    }

                    &:first-child {
                        margin-top 0
                    }

                }

                &-item {
                    position relative


                    transform translateZ(0)
                    will-change opacity
                    transition opacity 0.6s $ease-out-quart

                    &:hover {
                        opacity 1 !important
                    }

                    &:before {
                        content ""
                        position absolute
                        width 3px
                        height 3px
                        border-radius 50%
                        background $colors['white'];
                        left -20px
                        top 10px
                    }

                    svg {
                        margin-left 6px
                    }
                }
            }
        }

        &-nav {
            position absolute
            top 50%
            left 40px
            transform translateY(-50%)

            &-item {
                display block

                opacity 0
                transform translateY(80px) translateZ(0)
                will-change transform, opacity

                .is-page-enter & {
                    opacity 1
                    transform translateZ(0)

                    for n in (1..10) {
                        &:nth-child({n}) {
                            transition transform 0.8s $ease-out-quart (n * 0.05s), opacity 0.8s $ease-out-quart (n * 0.05s)
                        }
                    }
                }

                &-btn {
                    display block
                    line-height 30px
                    min-height 30px

                    &:before {
                        content ""
                        position relative
                        vertical-align middle
                        display inline-block
                        width 3px
                        height 3px
                        border-radius 50%
                        background $colors['white'];
                        margin 0 20px

                        opacity 0.15
                        transform translateZ(0)
                        transition opacity 0.6s $ease-out-quart
                        will-change opacity
                    }

                    span {
                        opacity 0
                        transform translateZ(0)
                        transition opacity 0.6s $ease-out-quart
                        will-change opacity
                    }

                    &.is-active, &:hover {
                        &:before {
                            opacity 1
                        }

                        span {
                            opacity 1
                        }
                    }
                }
            }
        }

        &-line {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity

            $length = 4;

            for n in 0..4 {
                &--{n} {
                    .is-leave-down & {
                        transform translateY(80px) translateZ(0)
                        transition transform 0.8s $ease-in-quart (($length - n) * 0.1s), opacity 0.8s $ease-in-quart (($length - n) * 0.1s)
                    }

                    .is-leave-up & {
                        transform translateY(-80px) translateZ(0)
                        transition transform 0.8s $ease-in-quart (n * 0.1s), opacity 0.8s $ease-in-quart (n * 0.1s)
                    }

                    .is-active-down &, .is-active-up & {
                        .is-page-enter & {
                            opacity 1
                            transform translateZ(0)
                        }
                    }

                    .is-active-down & {
                        transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)

                        .is-page-ready & {
                            transition transform 0.8s $ease-out-quart (0.8s + $length * 0.1s + n * 0.1s), opacity 0.8s $ease-out-quart (0.8s + $length * 0.1s + n * 0.1s)
                        }
                    }

                    .is-active-up & {
                        transition transform 0.8s $ease-out-quart (($length - n) * 0.1s), opacity 0.8s $ease-out-quart (($length - n) * 0.1s)

                        .is-page-ready & {
                            transition transform 0.8s $ease-out-quart (0.8s + $length * 0.1s + (($length - n) * 0.1s)), opacity 0.8s $ease-out-quart (0.8s + $length * 0.1s + (($length - n) * 0.1s))
                        }
                    }
                }
            }
        }

        &-line-mobile {
            @media $breakpoints.sm {
                $length = 5;

                for n in 0..5 {
                    &--{n} {
                        .is-leave-down & {
                            transition transform 0.8s $ease-in-quart (($length - n) * 0.1s), opacity 0.8s $ease-in-quart (($length - n) * 0.1s)
                        }

                        .is-leave-up & {
                            transition transform 0.8s $ease-in-quart (n * 0.1s), opacity 0.8s $ease-in-quart (n * 0.1s)
                        }

                        .is-active-down & {
                            transition transform 0.8s $ease-out-quart (n * 0.1s), opacity 0.8s $ease-out-quart (n * 0.1s)

                            .is-page-ready & {
                                transition transform 0.8s $ease-out-quart (0.8s + $length * 0.1s + n * 0.1s), opacity 0.8s $ease-out-quart (0.8s + $length * 0.1s + n * 0.1s)
                            }
                        }

                        .is-active-up & {
                            transition transform 0.8s $ease-out-quart (($length - n) * 0.1s), opacity 0.8s $ease-out-quart (($length - n) * 0.1s)

                            .is-page-ready & {
                                transition transform 0.8s $ease-out-quart (0.8s + $length * 0.1s + (($length - n) * 0.1s)), opacity 0.8s $ease-out-quart (0.8s + $length * 0.1s + (($length - n) * 0.1s))
                            }
                        }
                    }
                }
            }
        }

        &-scroll {
            padding-bottom 90px
            // display none

            // .is-touch & {
            //     display block
            // }

            @media $breakpoints.sm {
                padding-bottom 42px
                // padding-bottom 5.6vh
                // padding-bottom calc(42 * 100vh / 900)
            }

            &-content {
                opacity 0
                transform translateZ(0)
                will-change opacity
                transition opacity 0.6s $ease-out-quart

                .is-active & {
                    opacity 1
                }
            }

        }

        &-btn {
            position absolute
            // bottom 225px //240px
            // bottom 32vh
            // bottom calc(320 * 100vh / 1125)

            left 50%

            width 72px
            height 72px

            border-radius 50%

            margin-left -36px
        }

    }
</style>